import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import './App.css';

// Custom Pages
import LoginPage2 from './pages/LoginPage2';

import 'tailwindcss/tailwind.css';
import AssessmentPage from './pages/AssessmentPage';
import DashboardPage from './pages/DashboardPage';
import ResultPage from './pages/ResultPage';

// Modules
import { container } from './State';


const AuthRoutes = () => (
  <Switch>
    {/* <Route component={LoginPage} path="/" exact /> */}
    <Route component={LoginPage2} path="/" exact />
    
  </Switch>
);

const AppRoutes = () => (
  <Switch>
    <Route component={DashboardPage} path="/" exact />
    <Route component={AssessmentPage} path="/assessment" />
    <Route component={ResultPage} path="/result" />
  </Switch>
);

function App() {

  const con = container.useContainer();

  return (
    <>
      <Router>{ ( con.user ) ? <AppRoutes /> : <AuthRoutes />}</Router>
    </>
  );
}

export default App;