import React from 'react'

// Styles
import '../styles/elements.css'

function Loading() {
    return (
        <>
            <div className="flex justify-center pt-20">
                <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-64 w-64"></div>
            </div>
            <h3 className="text-center pt-10">Gathering Your Inputs</h3>
        </>
    )
}

export { Loading }
