import React from 'react'


const defsLeft = [
    { title: 'SELF-DIRECTION', description: 'Independent thought and action — choosing, creating, exploring' },
    { title: 'STIMULATION', description: 'Excitement, novelty, and challenge in life' },
    { title: 'HEDONISM', description: 'Pleasure or sensuous gratification for oneself' },
    {
      title: 'ACHIEVEMENT',
      description: 'Personal success through demonstrating competence according to social standards',
    },
    { title: 'POWER', description: 'Social status and prestige, control or dominance over people and resources' },
  ]

  const defsRight = [
    {
      title: 'UNIVERSALISM',
      description:
        'Understanding, appreciation, tolerance, and protection for the welfare of all people and for nature',
    },
    {
      title: 'BENEVOLENCE',
      description:
        'Preserving and enhancing the welfare of those with whom one is in frequent personal contact (the ‘in-group’)',
    },
    {
      title: 'TRADITION',
      description:
        "Respect, commitment, and acceptance of the customs and ideas that one's culture or religion provides",
    },
    {
      title: 'CONFORMITY',
      description:
        'Restraint of actions, inclinations, and impulses likely to upset or harm others and violate social expectations or norms',
    },
    { title: 'SECURITY', description: 'Safety, harmony, and stability of society, of relationships, and of self' },
  ]


const ValueCategoryDefs = ({ valueColorMap }) => {  
    return (
      <div>
        <div className="flex md:flex-row flex-col text-sm">
          <div className="flex-auto md:w-1/4 w-full">
            {defsLeft.map((e) => (
              <div className="md:mb-8 mb-4">
                <h3 className={`font-bold text-base text-${valueColorMap[e.title.toLocaleLowerCase()]}`}>{e.title}</h3>
                {e.description}
              </div>
            ))}
          </div>
          <div className="flex-auto relative">
            <img src="/wheel.png" width="980" height="801" />
          </div>
          <div className="flex-auto md:w-1/4 w-full">
            {defsRight.map((e) => (
              <div className="md:mb-8 mb-4">
                <h3 className={`font-bold text-base text-${valueColorMap[e.title.toLocaleLowerCase()]}`}>{e.title}</h3>
                {e.description}
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }


export { ValueCategoryDefs }
