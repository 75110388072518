import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import ReactTooltip from "react-tooltip";

import "../styles/elements.css";

// components
import { ValueCategoryDefs } from "../components/ValueCategoryDefs";
import { ValuePriorities } from "../components/ValuePriorities";

// utils

import {
    getAverageCategories,
    sortByScore
} from "../utils/Calculations";

import PrintPage from "./PrintPage";

// Kinda of useless but too lazy to refactor
const valueColorMap = {
	"self-direction": "self-direction",
	stimulation: "stimulation",
	hedonism: "hedonism",
	achievement: "achievement",
	power: "power",
	universalism: "universalism",
	benevolence: "benevolence",
	tradition: "tradition",
	conformity: "conformity",
	security: "security",
};

export default function Results({ data }) {
	const avgCategories = getAverageCategories(data);
	const dataSortedByValue = sortByScore(data);
	const componentRef = useRef();
	const { t } = useTranslation(["questions"]);
	var dataTip =
		"<p><strong>How To Save Your Report As PDF</strong></p><br/><p><strong>Mac</strong></p><ol><li>Click “Print”</li><li>Go to “Destination” and select “Save as PDF”</li><li>Click “Save”</li></ol><br/><p><strong>Microsoft</strong></p><ol><li>Click “Print”</li><li>Go to “Select Printer” and select “Microsoft Print to PDF”</li><li>Click “Print”</li></ol><br/><p><strong>Chromebook</strong></p><ol><li>Click “Print”</li><li>Go to “Destination” and select “Save as PDF”</li><li>Click “Save”</li></ol>";

	const handlePdf = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<>
			<ReactTooltip place="bottom" />
			<div style={{ display: "none" }}>
				<PrintPage ref={componentRef} data={data} />
			</div>
			<button
				onClick={handlePdf}
				className="absolute focus:outline-none rounded-xl border-2 p-2 right-5 top-32 w-20"
			>
				Print
			</button>
			<p
				className="absolute right-5 top-44 cursor-pointer"
				multiline={true}
				data-html={true}
				data-tip={dataTip}
			>
				Download as PDF?
			</p>
			<div id="result-container">
				<table className="flex justify-center flex-col container m-auto px-4 lg:px-40 md:px-5">
					<div
						id="page1"
						className="flex justify-center flex-col container m-auto"
					>
						<div className="mt-10 mb-5 grid" id="superid">
							<div className="text-black text-3xl font-bold">
								{t("Value Themes")}
							</div>
						</div>
						<table>
							{avgCategories.map((item, index) => {
								return (
									<tr key={index} className="mt-5">
										<td className="text-md font-medium text-black w-1/6">
											{t(item.category)}
										</td>
										<td
											className={`text-md leading-none p-4 mt-1 font-medium text-white block rounded-lg ${
												valueColorMap[
													item.category
														.split(" ")
														.join("-")
														.toLocaleLowerCase()
												]
											}`}
											style={{
												width: `${(
													parseFloat(
														item.categoryAvg,
													) * 14
												).toFixed(2)}%`,
											}}
										>
											{item.categoryAvg}
										</td>
									</tr>
								);
							})}
						</table>
						<div className="mt-10 mb-5 grid">
							<div className="text-black text-3xl font-bold">
								{t('Value Theme Definitions')}
							</div>
						</div>
						<>
							<ValueCategoryDefs valueColorMap={valueColorMap} />
						</>

                        <>
                            {
                                true ? <FreeResults data={data}/> : <PaidResults data={data} t={t}/>
                            }
                        </>
					</div>

				</table>
			</div>
		</>
	);
}

function FreeResults({data}) {
    return (
        <>
                    <div className="mt-10 mb-5 grid">
                <div className="text-black text-3xl font-bold"> Your Value Priorities</div>
                <span className="w-full text-l">57 Values of Human Motivation </span>
            </div>


            <div className="preview-snippet">
                <ValuePriorities data={data} />
            </div>

            <div className="glass-wrapper over">
                <div className="glass over">
                    <a href="https://www.discoveryourvalues.com/personal-values-assessment">
                        <button  className="button-over-glass">See Full Report</button>
                    </a>
                </div>
            </div>
        </>
    )
}


function PaidResults({ data, t }) {
    return (
        <>
        
					<div id="page2">
						<div className="mt-10 mb-5 grid">
							<div className="text-black text-3xl font-bold">
								{" "}
								{t("Your Value Priorities")}
							</div>
							<span className="w-full text-l">
							{t("57 Values of Human Motivation")}{" "}
							</span>
						</div>
						<ValuePriorities data={data} />
					</div>

					<div id="page3">
						<div className="mt-10 mb-5 grid">
							<div className="text-black text-3xl font-bold py-4">
								{" "}
								{t('Value Scores')}
							</div>
						</div>
						<table>
							<tr className="value-scores-header border-b-2">
								<td style={{ width: "5%" }} />
								<td style={{ width: "45%" }}>{t('Value')}</td>
								<td style={{ width: "25%" }}>{t('Score')}</td>
								<td style={{ width: "25%" }}>{t('Category')}</td>
								<td style={{ width: "15%" }}>{t('Score')}</td>
							</tr>
							{data.map((value, index) => {
								return (
									<>
										<tr className="value-scores-row">
											{index < 9 ? (
												<td> 0{index + 1}. </td>
											) : (
												<td> {index + 1}. </td>
											)}
											<td> {t(value.noun)} </td>
											<td> {value.selectedValue} </td>
											<td> {t(value.category)} </td>
											<td> {value.categoryAvg} </td>
										</tr>
									</>
								);
							})}
						</table>
					</div>
        </>
    )
}