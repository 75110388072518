import React from "react";
import { useTranslation } from "react-i18next";
import { container } from "../State";
import "../styles/elements.css";
import {
    getAverageCategories
} from "../utils/Calculations";

// IMAGES
const WHEEL_ENGLISH = process.env.PUBLIC_URL + '/wheel.png';
const WHEEL_SPANISH =  process.env.PUBLIC_URL + '/wheel-es.jpeg';


const defsLeft = [
	{
		title: "SELF-DIRECTION",
		description:
			"Independent thought and action — choosing, creating, exploring",
	},
	{
		title: "STIMULATION",
		description: "Excitement, novelty, and challenge in life",
	},
	{
		title: "HEDONISM",
		description: "Pleasure or sensuous gratification for oneself",
	},
	{
		title: "ACHIEVEMENT",
		description:
			"Personal success through demonstrating competence according to social standards",
	},
	{
		title: "POWER",
		description:
			"Social status and prestige, control or dominance over people and resources",
	},
];

const defsRight = [
	{
		title: "UNIVERSALISM",
		description:
			"Understanding, appreciation, tolerance, and protection for the welfare of all people and for nature",
	},
	{
		title: "BENEVOLENCE",
		description:
			"Preserving and enhancing the welfare of those with whom one is in frequent personal contact (the ‘in-group’)",
	},
	{
		title: "TRADITION",
		description:
			"Respect, commitment, and acceptance of the customs and ideas that one's culture or religion provides",
	},
	{
		title: "CONFORMITY",
		description:
			"Restraint of actions, inclinations, and impulses likely to upset or harm others and violate social expectations or norms",
	},
	{
		title: "SECURITY",
		description:
			"Safety, harmony, and stability of society, of relationships, and of self",
	},
];

const valueColorMap = {
	"self-direction": "self-direction",
	stimulation: "stimulation",
	hedonism: "hedonism",
	achievement: "achievement",
	power: "power",
	universalism: "universalism",
	benevolence: "benevolence",
	tradition: "tradition",
	conformity: "conformity",
	security: "security",
};

export const PrintPage = React.forwardRef((props, ref) => {
	const con = container.useContainer();
	var data = props.data;
	const avgCategories = getAverageCategories(data);

	avgCategories.sort(function (a, b) {
		return b.categoryAvg - a.categoryAvg;
	});

	const cols = () => {
		const rows = [...Array(Math.ceil(data.length / 19))];
		const productRows = rows.map((row, idx) =>
			data.slice(idx * 19, idx * 19 + 19),
		);

		return productRows.map((row, idx) => {
			return (
				<div className="w-full m-1 relative">
					{row.map((value, indx) => {
						return (
							<div
								className={`widget m-1 relative w-full  rounded-lg  shadow-md bg-white  border-l-8 border-${
									valueColorMap[
										value.category
											.split(" ")
											.join("-")
											.toLocaleLowerCase()
									]
								}`}
							>
								<div className="flex items-center">
									<div
										className={`icon w-11 p-3.5 ${
											((idx === 0 && indx === 0) ||
												(idx === 0 && indx === 1) ||
												(idx === 0 && indx === 2) ||
												(idx === 0 && indx === 3) ||
												(idx === 0 && indx === 4)) &&
											valueColorMap[
												value?.category
													.split(" ")
													.join("-")
													.toLocaleLowerCase()
											]
										} text-xs text-${
											(idx === 0 && indx === 0) ||
											(idx === 0 && indx === 1) ||
											(idx === 0 && indx === 2) ||
											(idx === 0 && indx === 3) ||
											(idx === 0 && indx === 4)
												? "white"
												: "black"
										} rounded-full mr-3 text-center`}
									>
										{
											idx === 1
												? indx + 1 + 19
												: idx === 2
												? indx + 1 + (19 + 19)
												: indx + 1 // not very proud of this one D;
										}
									</div>
									<div className="flex flex-col justify-center">
										<div className="text-xs">
											{t(value.noun)}
										</div>
										<div className="text-xs text-gray-400">
											{t(value.category)}
										</div>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			);
		});
	};

	const { t } = useTranslation(["questions"]);

	return (
		<div ref={ref} className="page">
			<div className="page1">
				<div className="text-black text-2xl mb-4 font-bold">
					{t("Value Themes")}
				</div>
				<table>
					{avgCategories.map((item, index) => {
						return (
							<tr key={index} className="mt-5 w-100">
								<td className="mr-5 text-md font-medium text-black w-1/4">
									{t(item.category)}
								</td>
								<td
									className={`text-xs ml-5 leading-none  p-4 mt-1 font-medium text-white block rounded-lg ${
										valueColorMap[
											item.category
												.split(" ")
												.join("-")
												.toLocaleLowerCase()
										]
									}`}
									style={{
										width: `${(
											parseFloat(item.categoryAvg) * 30
										).toFixed(2)}%`,
									}}
								>
									{item.categoryAvg}
								</td>
							</tr>
						);
					})}
				</table>

				<div className="text-black text-2xl mt-8 mb-4 font-bold">
					{t('Value Theme Definitions')}
				</div>
				<div className="flex flex-row  text-sm">
					<div className="flex-auto w-1/3 ">
						{defsLeft.map((e) => (
							<div className="mb-4 text-xs">
								<h3
									className={`font-bold text-xs text-${
										valueColorMap[
											e.title.toLocaleLowerCase()
										]
									}`}
								>
									{t(e.title)}
								</h3>
								{t(e.description)}
							</div>
						))}
					</div>
					<div className="flex-auto relative">
						<img
							src={
								con.currentLanguage === "en"
									? WHEEL_SPANISH
									: WHEEL_ENGLISH
							}
							width="300"
						/>
					</div>
					<div className="flex-auto w-1/3 ">
						{defsRight.map((e) => (
							<div className="mb-4 text-xs">
								<h3
									className={`font-bold text-xs text-${
										valueColorMap[
											e.title.toLocaleLowerCase()
										]
									}`}
								>
									{t(e.title)}
								</h3>
								{t(e.description)}
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
});

export default PrintPage;