import React from 'react'

import { useHistory } from 'react-router-dom'
import { container } from '../State';

export const Header = () => {
  const con = container.useContainer();
  const history = useHistory()

  const signOut = () => {
    con.setUser(localStorage.setItem("discover-your-value", null))
    history.push("/")
  }

  return (
    <nav className="flex items-center justify-between flex-wrap bg-teal px-6 p-5 border-b-2">
      <div className="flex items-center flex-no-shrink mr-6">
        <span onClick={() => { history.push("/") }} className="cursor-pointer font-semibold text-xl tracking-tight">
            <div className="flex justify-center">
                <img src="/logo-header.png" alt="me" className="logo-img-header" />
            </div>
        </span>
      </div>
      <div className="block lg:hidden" />
      <div className="block">
        {/* <div>
          <a
            href="#"
            className="inline-block text-sm px-4 py-2 leading-none border rounded hover:border-transparent hover:text-teal hover:bg-white mt-4 lg:mt-0"
            onClick={() => signOut()}
          >
            Log Out
          </a>
        </div> */}
        <div>
          <a
            href="#"
            className="inline-block text-sm px-4 py-2 leading-none border rounded hover:border-transparent hover:text-teal hover:bg-white mt-4 lg:mt-0"
            onClick={() => signOut()}
          >
            Exit
          </a>
        </div>
      </div>
    </nav>
  )
}