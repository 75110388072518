import React from 'react'

import { container } from '../State';

import "../styles/elements.css";

export default function LoginPage2( { history }) {

  const con = container.useContainer();

  const handleSubmit = () => {
    con.setUser(localStorage.setItem("discover-your-value", "User"))
    history.push("/")
  }

  return(

    <div className="flex justify-center justify-items-center items-center w-full h-screen m-0">

      <div className="p-8 max-w-lg bg-white shadow-md rounded-lg overflow-hidden mx-auto">
        <div className="flex justify-center">
          <img src="/logo-main.png" alt="me" className="logo-img"/>
        </div>
        {/* <h4 className="font-ibm text-2xl text-center m-5"> Discover Your Values </h4> */}

        <p className="font-ibm text-center my-5 "> Personal Values Assessment </p>

        <form onSubmit={handleSubmit}>
          <button 
            style={{ backgroundColor: '#29abe2', color: 'white' }}
            className="w-full text-white my-5 text-black focus:outline-none h-12 px-6 text-lg rounded transition-colors duration-150 rounded-lg disabled:opacity-50 disabled:cursor-default bg-blue" type="submit">
            Start
          </button>
        </form>

      </div>
    </div>
  )
}