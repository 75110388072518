import React, { useEffect, useState } from 'react'

// components
import { ValueCategoryDefs } from '../components/ValueCategoryDefs'
import { ValuePriorities } from '../components/ValuePriorities'

import { Header } from '../components/Header'
import { Loading } from '../components/Loading'

// utils

import { getAverageCategories, purifyDataWithAvgCategory, sortByScore } from "../utils/Calculations"
import Results from './Results'


// Kinda of useless but too lazy to refactor 
const valueColorMap = {
    'self-direction': 'self-direction',
    stimulation: 'stimulation',
    hedonism: 'hedonism',
    achievement: 'achievement',
    power: 'power',
    universalism: 'universalism',
    benevolence: 'benevolence',
    tradition: 'tradition',
    conformity: 'conformity',
    security: 'security',
  }


function ResultPage({ location }) {

    const [ loading, setLoading ] = useState(true)
    const data = purifyDataWithAvgCategory(location.state)

    // FOR TESTING 
    // const data = purifyDataWithAvgCategory(MOCK_ANSWERS) 

    useEffect(() => {
        setTimeout(() => {
            // Send Stuff to DB
            setLoading(false)
        }, 500);
    }, [])

    return (
        <div>
            <Header />

            { loading ? <Loading /> : <Results data={data}/> }
        </div>
    )
}

export function FreeResults({ data }) {
    
    const avgCategories = getAverageCategories(data)
    const dataSortedByValue = sortByScore(data)

    return(
        <table className="flex justify-center flex-col container m-auto px-4 lg:px-40 md:px-5">
        <div className="mt-10 mb-5 grid" id="superid">       
            <div className="text-black text-3xl font-bold" >Value Category Profile</div>
            </div>
            <table className="" >

            {
            avgCategories.map((item, index) => {
                return (
                    <tr key={index} className="mt-5">
                        <td className="text-md font-medium text-black w-1/6">{item.category}</td>
                        <td
                        className={`text-md leading-none p-4 mt-1 font-medium text-white block rounded-lg ${
                            valueColorMap[item.category.split(' ').join('-').toLocaleLowerCase()]
                        }`}
                        style={{ width: `${(parseFloat(item.categoryAvg) * 15).toFixed(2)}%` }}
                        >
                        {item.categoryAvg}
                        </td>
                    </tr>
                )
                })
            }
            </table>
            <div className="mt-10 mb-5 grid">
            <div className="text-black text-3xl font-bold">Value Category Definitions</div>
            </div>
            <>
                <ValueCategoryDefs valueColorMap={valueColorMap}/>
            </>
            <div className="mt-10 mb-5 grid">
                <div className="text-black text-3xl font-bold"> Your Value Priorities</div>
                <span className="w-full text-l">57 Values of Human Motivation </span>
            </div>


            <div className="preview-snippet">
                <ValuePriorities data={data} />
            </div>

            <div className="glass-wrapper over">
                <div className="glass over">
                    <a href="https://www.discoveryourvalues.com/personal-values-assessment">
                        <button  className="button-over-glass">See Full Report</button>
                    </a>
                </div>
            </div>

        </table>
    )
}

export default ResultPage
