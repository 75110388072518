import React, { useState } from 'react';
import { Header } from '../components/Header';
import { container } from '../State';

// util
import QUESTIONS from '../utils/questions.json';

// Const
const LAST_QUESTION = 56; // 56 is the last question

function AssessmentPage({ history }) {

    // Global State
    const con = container.useContainer();

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
    const [currentQuestionValue, setCurrentQuestionValue] = useState(0)

    const [isValueSelected, setIsValueSelected] = useState(false)
    const [isValueSelectedFinal, setIsValueSelectedFinal] = useState(false)


    const handlePickValueRate = (e, item) => {
        setIsValueSelected(true)
        setCurrentQuestionValue(e.target.value)

        // Last Question
        if (currentQuestionIndex === LAST_QUESTION & !isValueSelectedFinal) {
                setIsValueSelectedFinal(true)
                con.setAssessmentResult([
                    ...con.assessmentResult, 
                    {
                        noun: QUESTIONS[currentQuestionIndex].noun,
                        category: QUESTIONS[currentQuestionIndex].category,
                        description: QUESTIONS[currentQuestionIndex].description,
                        selectedValue: e.target.value
                    }
                ])
        }
        
    }

    // Right Button
    const handleOnClickNext = () => {
        con.setAssessmentResult([
            ...con.assessmentResult, 
            {
                noun: QUESTIONS[currentQuestionIndex].noun,
                category: QUESTIONS[currentQuestionIndex].category,
                description: QUESTIONS[currentQuestionIndex].description,
                selectedValue: currentQuestionValue
            }
        ])
        setCurrentQuestionIndex(currentQuestionIndex + 1);

        setIsValueSelected(false)
        setCurrentQuestionValue(0)
    }

    // Left button
    const handleOnClickPrevious = () => {
        let newResults = con.assessmentResult;
        newResults.pop();
        con.setAssessmentResult(newResults)

        setCurrentQuestionValue(0)
        setCurrentQuestionIndex(currentQuestionIndex - 1);
        setIsValueSelectedFinal(false)

        // Last Question // need to pop an extra time if its las tq
        if (currentQuestionIndex === LAST_QUESTION) {
            newResults.pop()
            con.setAssessmentResult(newResults)
        }
    }

    // 
    const handleSubmit = () => {

        // update State and the store in local storage
        con.setPreviousAssessments([...con.previousAssessments, con.assessmentResult])
        localStorage.setItem("discover-your-values-previousAssessments", JSON.stringify([...con.previousAssessments, con.assessmentResult]))

        history.push({ 
            pathname: "/result" ,
            state: con.assessmentResult
        })
    }

    return (
        <div>
            <Header />

            <p className="font-ibm text-right text-3sm md:text-2sm pr-11">{currentQuestionIndex+1}/57</p>

            <div className="flex justify-center flex-col container m-auto w-full mt-10 md:mt-20">
                <span className="font-ibm text-center text-xl md:text-1xl m-2">As a guiding principle in my life, how important is</span>
                <div className="flex text-center justify-center flex-col">
                    <span className="font-ibm font-medium text-3xl md:text-4xl m-2">
                        { QUESTIONS[currentQuestionIndex].noun }
                    </span>
                    <span className="font-ibm font-italic text-md md:text-xl m-2 mb-4">
                        { QUESTIONS[currentQuestionIndex].description }
                    </span>
                    <div>
                        {[0,1,2,3,4,5,6,7].map((item, index) => (
                        <button
                        
                            disabled={isValueSelectedFinal}
                            key={index}
                            onClick={(e) => handlePickValueRate(e, item)}
                            variation='primary'
                            className={`m-3 h-14 px-7 text-xl rounded text-white backgound-blue focus:outline-none ${item.toString() === currentQuestionValue ? 'outline-none ring-4 ring-blue-500 ring-opacity-1' : ''}`}
                            value={item}
                        >
                            {item}
                        </button>
                        ))}
                    </div>
                </div>
            </div>

            <div className="flex">
                <button 
                    onClick={handleOnClickPrevious} 
                    variation="outline" className="m-auto"
                    disabled={currentQuestionIndex=== 0}
                    >
                    <svg
                        style={{ margin: 'auto' }}
                        className={currentQuestionIndex=== 0 ? "cursor-pointer border-b-2 hover:translate-2 opacity-10" : "cursor-pointer border-b-2 hover:translate-2"}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        height="50"
                        width="50"
                        stroke="currentColor"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg>
                </button>
                <button
                    disabled={!isValueSelected || currentQuestionIndex === LAST_QUESTION}
                    variation="outline"
                    className="m-auto"
                    onClick={handleOnClickNext}
                >
                    <svg
                        style={{ margin: 'auto' }}
                        className={currentQuestionIndex === LAST_QUESTION || !isValueSelected ? "cursor-pointer border-b-2 hover:translate-2 opacity-10" : "cursor-pointer border-b-2 hover:translate-2"}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        height="50"
                        width="50"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                    </svg>
                </button>
            </div>            
            {
                currentQuestionIndex === LAST_QUESTION && isValueSelected?             
                <div className="text-center">
                    <button 
                        style={{ backgroundColor: '#29abe2', color: 'white' }}
                        onClick={handleSubmit}
                        className="text-center text-white backgound-blue my-5 text-black focus:outline-none h-12 px-6 text-lg rounded transition-colors duration-150 rounded-lg disabled:opacity-50 disabled:cursor-default" type="submit">
                        Get Your Results
                    </button>
                </div> 
                : null
            }


                {/* <div className="text-center">
                    <button 
                        onClick={handleSubmit}
                        className="text-center text-white bg-indigo-600 my-5 text-black focus:outline-none h-12 px-6 text-lg rounded transition-colors duration-150 rounded-lg disabled:opacity-50 disabled:cursor-default" type="submit">
                        Submit
                    </button>
                </div>  */}


        </div>
    )
}

export default AssessmentPage
