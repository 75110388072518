import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { container } from "./State";
import 'tailwindcss/tailwind.css'


ReactDOM.render(
  <container.Provider>
    <App />
  </container.Provider>,
  document.getElementById('root')
);
